@import "./variables.scss";

body {
  font-family: $primary-font;
  background: $bg-color;
  color: $black;
  font-size: $primary-fs;
}

h1 {
  @include head1;
}

.form-control,
.form-select,
button {

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}

.form-control,
.form-select {
  &.error {
    border-color: $error-red !important;
  }
}

a {
  color: $primary-color;
  text-decoration: none;
  transition: 0.5s;

  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: none;
  }
}

textarea {
  height: 105px !important;
  resize: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.red {
  color: $error-red !important;
}

//Button Style
.btn {
  &.btn-primary {
    background: $primary-color;
    border-color: $primary-color;
    @include btn-main;

    &:active {
      background: $primary-color-hover;
      border-color: $primary-color-hover;
      box-shadow: inset 0 5px 11px 0 rgba(130, 194, 3, 0.5),
        0 4px 15px 0 rgba(130, 194, 3, 0.5) !important;
    }

    &:focus-visible {
      box-shadow: none;
    }

    &:disabled {
      background: $readonly;
      border-color: $readonly;
    }
  }

  &.btn-secondary {
    background: $btn-sec;
    border-color: $btn-sec;
    @include btn-main;

    &:active {
      background: $pagination;
      border-color: $pagination;
      box-shadow: inset 0 5px 11px 0 rgba(87, 87, 93, 0.5),
        0 4px 15px 0 rgba(87, 87, 93, 0.5) !important;
    }

    &:focus-visible {
      box-shadow: none;
    }
  }

  &.btn-outline-primary {
    background: $white;
    border-color: $primary-color;
    color: $black;
    @include btn-main;

    &:active {
      background: $white;
      color: $black;
      border-color: $black;
      box-shadow: 0 5px 11px 0 rgba(235, 235, 235),
        0 4px 15px 0 rgba(235, 235, 235) !important;
    }

    &:focus-visible {
      box-shadow: none;
    }

    &:disabled {
      border-color: $readonly;
      color: $readonly;
    }
  }

  span {
    &.pending-aprovals {
      background: $secondary-color !important;
      font-weight: $semi-bold;
      color: $black;
      margin-left: 10px;
      min-width: 24px;
      padding: 5px;
      font-size: $primary-fs;
    }
  }
}

.btn-close {
  background-image: url(../img/close.svg);
  width: 22px;
  height: 22px;
  opacity: 1;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    opacity: 0.5;
  }
}

.min-w-auto {
  min-width: auto !important;
}

.no-margin {
  * {
    margin: 0 !important;
  }
}

//Inner Main Style
.content-wrap {
  padding-top: 40px;
  padding-bottom: 40px;

  h1 {
    @include head1;
  }

  h2 {
    @include head2;
  }

  .inner-head {
    padding: 30px 0;
    border-bottom: 1px solid $action-bd-color;
  }

  .heatmap-dtl {
    justify-content: space-between;
    padding-top: 15px;

    @media (max-width:1200px) {
      flex-direction: column;
    }

    p {
      color: #191919;

      a {
        font-weight: $semi-bold;
      }
    }
  }

  .action-wrap {
    border-bottom: 1px solid $action-bd-color;
    padding-bottom: 30px;

    .btn {
      height: 50px;
      font-size: $primary-fs;
      margin-right: 22px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        stroke: $white;
        margin-right: 10px;
        fill: $white;
      }

      @media (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    h1 {
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }

      span {
        font-weight: $regular;
      }
    }
  }

  .search-wrap {
    padding: 30px 0;

    .btn-primary {
      height: 48px;
      font-size: 16px;

      svg {
        fill: $white;
        margin-right: 10px;
      }
    }
  }

  .heatmap {
    margin-top: 20px;
  }
}

//Tab Style
.tab-wrap {
  .btn-notify {
    position: absolute;
    right: 0;
    top: 0;
    font-size: $primary-fs;
    font-weight: $semi-bold;
    height: 48px;

    svg {
      stroke: $white;
      margin-right: 10px;
    }

    @media (max-width: 992px) {
      position: inherit;
      margin-bottom: 20px;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.nav-tabs {
  border-color: $action-bd-color;

  .nav-item {
    @media (max-width: 576px) {
      width: 100%;
    }

    .nav-link {
      border: none;
      outline: none;
      font-size: $top-nav-fs;
      font-weight: $bold;
      color: $label-color;
      padding: 5px 60px 25px;

      &:first-child {
        padding-left: 0;
      }

      &.active {
        color: $primary-color;
        background: none;
        border: none;
      }

      @media (max-width: 576px) {
        margin: auto;
        padding-right: 0;
      }
    }
  }
}

.tab-content {
  .product-dtls-head {
    display: flex;
    align-items: center;
    padding: 10px 0;

    h3 {
      font-size: 27px;
      font-weight: $bold;
      margin: 0;

      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .btn {
      margin-right: 22px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        stroke: $white;
        margin-right: 10px;
      }

      @media (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}

//Table Style
.table {
  thead {
    tr {
      th {
        background: $thead-color;
        vertical-align: middle;
        font-weight: $semi-bold;
        color: $check-bd;
        padding: 15px;
        min-height: 61px;
        white-space: nowrap;

        &.commission {
          width: 125px;
        }

        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        td {
          background: #f6f6f6;
        }
      }

      &.highlight {
        td {
          background: rgba(148, 218, 10, 0.1);
        }
      }

      td {
        background: $white;
        vertical-align: middle;
        padding: 15px;
        transition: 0.8s;
        border-color: $table-bd;
        white-space: nowrap;

        @media (max-width: 1200px) {
          white-space: nowrap;
        }

        &.shipping-status {
          .btn {
            min-width: 100px;
            height: 30px;
            font-size: 10px;
            font-weight: $medium;
            padding: 0;

            &:hover,
            &:focus,
            &:active {
              text-decoration: underline;
              box-shadow: none !important;
            }

            &.setup {
              background: $white;
              border-color: $check-bd;
              color: $check-bd;
            }

            &.delivered {
              background: #B9FECD;
              border-color: #B9FECD;
              color: #0F8633;
            }

            &.shipped {
              background: #FDEFB2;
              border-color: #FDEFB2;
              color: #640424;
            }
          }

          svg {
            margin-left: 8px;
            cursor: pointer;
          }

          .not-avail {
            font-weight: $bold;
            color: $primary-color;
            font-size: $primary-fs;
            width: 100px;
          }
        }

        &.user-photo {
          img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin-right: 15px;
          }

          .user-info {
            a {
              width: 150px;
              overflow: hidden;
              display: block;
              text-overflow: ellipsis;
            }

            span {
              font-size: $link-fs;
              padding-top: 2px;
            }
          }

          &.location {
            display: flex;
            align-items: center;
          }
        }

        &.offer-name {
          p {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.amt {
          font-weight: $bold;
          color: $primary-color;
          white-space: nowrap;
        }

        &.qty,
        &.commission {
          color: $primary-color;
          white-space: nowrap;
        }

        &.refund {
          .not-avail {
            font-weight: $bold;
            color: $primary-color;
            font-size: $primary-fs;
          }

          span {
            width: fit-content;
            height: 26px;
            border-radius: 13px;
            font-size: $link-fs;
            font-weight: $regular;
            padding-top: 7px;
            margin: auto;

            &.requested {
              background: #ebebeb !important;
              color: #434343;
            }

            &.initiated {
              background: #b9fecd !important;
              color: #0f8633;
            }

            &.rejected {
              background: #fddade !important;
              color: #640424;
            }
          }
        }

        &.action {
          .btn {
            margin-right: 13px;

            &.btn-primary,
            &.btn-outline-primary {
              padding: 0 10px;
              height: 30px;
              min-width: 80px;
              font-size: $link-fs;
              font-weight: $regular;
              white-space: nowrap;
            }

            &:last-child {
              margin-right: 0;

              @media (max-width: 576px) {
                margin-top: 10px;
              }
            }
          }

          a {
            &.active {
              color: $green;

              &:hover {
                text-decoration: none;
              }
            }

            &.block {
              color: $block;

              &:hover {
                text-decoration: none;
              }
            }

            &.reject {
              color: #eaad00;

              &:hover {
                text-decoration: none;
              }
            }
          }

          svg {
            fill: #6d7177;
            transition: 0.5s;
          }

          &:hover {
            svg {
              fill: $primary-color;
              cursor: pointer;
            }
          }
        }

        &.download {
          svg {
            cursor: pointer;
          }
        }

        &.sub-status {
          span {
            &.success {
              color: $green;
            }

            &.pending {
              color: #eaad00;
            }

            &.failed {
              color: $block;
            }

            &.cancelled {
              color: $primary-color-hover;
            }
          }
        }

        a {
          color: $black;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;

          &:first-child {
            border-radius: 0 0 0 8px;
          }

          &:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }
    }
  }
}

//Pagination Style
.dash-pagination {
  background: $white;
  border-radius: 15px;
}

.paginate-wrap {
  position: relative;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  span {
    &.records {
      position: absolute;
      top: 18px;
      left: 0;

      @media (max-width: 992px) {
        position: inherit;
        margin-bottom: 20px;
      }
    }
  }
}

.pagination {
  margin-top: 15px;

  .page-item {
    .page-link {
      background: $white;
      font-size: $link-fs;
      font-weight: $medium;
      color: $pagination;
      @include b-radius;
      border: 0;
      margin: 0 3px;
      min-width: 24px;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        background: $primary-color;
        color: $white;
      }
    }

    &.previous {
      .page-link {
        background: url(../img/arrow-left.svg) center center no-repeat $white;

        span {
          opacity: 0;
        }
      }
    }

    &.next {
      .page-link {
        background: url(../img/arrow-right.svg) center center no-repeat $white;

        span {
          opacity: 0;
        }
      }
    }
  }
}

//Modal Style
.modal-content {
  @include modal-radius;

  .modal-header {
    padding: 30px 30px 0;
    border: none;

    .modal-title {
      font-size: $head1-fs;
      font-weight: $bold;
    }
  }

  .modal-body {
    padding: 30px;

    h5 {
      font-size: $top-nav-fs;
      font-weight: $semi-bold;
      line-height: 28px;
    }

    .content-area {
      margin-top: 10px;

      h3 {
        font-size: $primary-fs;
        font-weight: $semi-bold;
      }

      p {
        font-size: $form-label-fs;
        line-height: 24px;
      }
    }

    &.table-wrap {
      padding-left: 0;
      padding-right: 0;

      th {
        border-radius: 0;
      }

      .paginate-wrap {
        margin: 0 15px;
      }
    }

    &.terms {
      h2 {
        font-size: $top-nav-fs;
        font-weight: $semi-bold;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 35px;
        line-height: 28px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.list-product-pop {
      h3 {
        font-weight: $semi-bold;
        font-size: 26px;
        margin-bottom: 20px;
      }

      p {
        font-size: $prodt-title-fs;
        line-height: 28px;
        margin-bottom: 20px;

        span {
          font-weight: $bold;
        }
      }

      .message {
        font-family: $secondary-font;
        background: $thead-color;
        padding: 20px;
        line-height: 22px;
        font-size: $form-input-fs;
        @include b-radius;

        &::before {
          content: url(../img/polygon.svg);
          width: 26px;
          height: 13px;
          position: absolute;
          left: 10px;
          top: -13px;
        }
      }

      .account-type {
        margin: 40px 0;

        h3 {
          font-size: $top-nav-fs;
        }

        .form-check-label {
          padding: 0 20px 0 10px;
        }
      }

      .info-wrap {
        background: $thead-color;
        padding: 15px;
        font-size: $form-input-fs;
        box-shadow: 0px 3px 6px #00000029;
        @include b-radius;
      }

      .btn-wrap {
        @media (max-width: 576px) {
          display: flex;
          flex-direction: column;
        }

        .btn {
          margin-right: 20px;

          @media (max-width: 576px) {
            margin-right: 0px;
            margin-bottom: 20px;

            &:last-child {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }

        &.second-row {
          display: flex;
          align-items: center;

          @media (max-width: 992px) {
            flex-direction: column;
          }

          p {
            background: url(../img/schedule-info.svg) 0 center no-repeat;
            margin-bottom: 0;
            padding-left: 35px;
            font-size: $link-fs;
            font-style: italic;
            line-height: 26px;

            @media (max-width: 1200px) {
              line-height: 16px;
            }

            @media (max-width: 992px) {
              line-height: 26px;
            }
          }
        }
      }

      .schedule-info {
        @media (max-width: 992px) {
          flex-direction: column;
          align-items: start !important;
          margin-top: 20px;
        }

        p {
          font-weight: $semi-bold;
          font-size: $primary-fs;

          @media (max-width: 992px) {
            margin-bottom: 20px !important;
          }

          span {
            font-weight: $light;
            font-style: italic;
            padding-left: 20px;
          }
        }

        .btn {
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }

      .schedule-wrap {
        background: $bg-color;
        padding: 30px;
        @include b-radius;
        margin-top: 30px;

        .date-picker {
          @media (max-width: 992px) {
            margin-bottom: 20px;
          }
        }

        .btn-wrap {
          .btn {
            @media (max-width: 992px) {
              margin-right: 0;
              margin-bottom: 20px;
              width: 100%;
            }
          }
        }
      }

      .proxy-pin {
        .check-box {
          margin-bottom: 20px;

          input {
            margin-top: 2px;
          }

          label {
            padding-left: 10px;
            font-size: $form-input-fs;
          }
        }
      }
    }

    &.shipment-dtls {
      .shipment-info {
        background: url(../img/ic_shipment.svg) 0 0 no-repeat;
        color: #333333;
        padding: 12px 0 30px 120px;
        border-bottom: 1px solid rgba(112, 112, 112, .19);
        ;

        span {
          font-size: $form-input-fs;
        }

        p {
          font-size: $top-nav-fs;
          font-weight: $bold;
          margin-top: 3px;
        }
      }

      .shipment-status {
        padding: 60px 0 30px;

        .status-wrap {
          width: calc(100% / 3);

          &::after {
            content: " ";
            height: 1px;
            background: #E4E4E4;
            position: absolute;
            width: calc(50% - 12px);
            right: 0;
            top: 9px;
          }

          &::before {
            content: " ";
            height: 1px;
            background: #E4E4E4;
            position: absolute;
            width: calc(50% - 12px);
            left: 0;
            top: 9px;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          .step {
            width: 18px;
            height: 18px;
            border-radius: 9px;
            background: #E4E4E4;
            z-index: 10;
            margin-bottom: 10px;
          }

          .status {
            color: rgba(51, 51, 51, .5);
            font-size: 16px;
            margin-bottom: 2px;
          }

          .date {
            font-size: $primary-fs;
            color: rgba(51, 51, 51, .5);
            ;
          }

          &.active {
            .step {
              background: #00A706;
            }

            &::before {
              background: #00A706;
            }

            &::after {
              background: #00A706;
            }

            .status {
              color: #00A706;
              ;
            }
          }
        }
      }
    }

    &.generate-label-modal {
      .address {
        background: url(../img/ic_address.svg) 0 0 no-repeat;
        padding-left: 58px;
        line-height: 24px;
        margin-top: 6px;
        margin-bottom: 30px;

        span {
          font-weight: $bold;
          margin-top: 10px;
        }

        p {
          margin-bottom: 0;
        }

      }

      .package-details {
        border-top: 1px solid #E9E9E9;
        padding: 30px 0;

        h3 {
          font-size: $top-nav-fs;
          font-weight: $semi-bold;
          margin-bottom: 20px;
        }

        .package-info {
          margin-bottom: 30px;

          label {
            font-size: $link-fs;
            margin-bottom: 10px;
          }

          span {
            font-weight: $semi-bold;
          }
        }

        .package-fee {

          p {
            margin-bottom: 8px;


          }

          span {
            font-weight: $semi-bold;
          }

        }

        .total-fee {
          border-top: 1px solid #E9E9E9;
          font-size: $top-nav-fs;
          font-weight: $bold;
          padding-top: 10px;
          margin-top: 10px;
        }
      }

      .verify {
        p {
          span {
            font-weight: $semi-bold;
          }
        }
      }

      .package-alert {
        p {
          background: url(../img/package-info.svg) 0 5px no-repeat;
          color: #E60000;
          padding-left: 30px;
        }
      }

      .saved-cards {
        background: $bg-color;
        padding: 20px;
        @include modal-radius;

        p {
          &.card-number {
            font-size: $top-nav-fs;
            letter-spacing: 5px;
            font-weight: $medium;

            @media (max-width: 576px) {
              letter-spacing: 2px;
            }
          }
        }

        a {
          color: $error-red;
        }
      }
    }
  }

  .modal-footer {
    border: none;
    padding: 0px 30px 30px 30px;
  }
}

.label-confirm {
  background: rgba($black, $alpha: 0.5);
}

.rates-modal {
  max-width: none;
  width: 90%;

  .table {
    font-size: $link-fs;

    thead {
      tr {
        th {
          padding: 15px 20px;

          &:first-child {
            border-radius: 0 !important;
          }

          &:last-child {
            border-radius: 0 !important;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          td {
            background: transparent;
          }
        }

        td {
          padding: 20px;

          &:hover,
          &.active {
            background: transparent;
            cursor: pointer;
          }
        }

        td {
          &.rate-select {

            &:hover,
            &.active {
              background: #AAE23D;
            }
          }
        }
      }
    }
  }
}

.loader-pop {
  .modal-content {
    background: none !important;
    border: none;
  }
}

//Not Found Style
.not-found {
  min-height: calc(100vh - 96px);

  img {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 23px;
    font-weight: $medium;
    margin-bottom: 20px;
  }

  p {
    font-size: $top-nav-fs;
    color: $label-color;
    max-width: 610px;
    margin: 0 auto 30px;
  }
}

.form-check-input {
  width: 20px;
  height: 20px;
  border: 2px solid $check-bd;
  margin: 0;
  cursor: pointer;

  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 194, 3, 0.25) !important;
  }
}

//Inner Pages
.inner-main {
  background: $white;
  border-radius: 15px;
  padding: 30px 0;
  margin-top: 20px;

  &.profile {
    h3 {
      font-weight: $semi-bold;
      font-size: $top-nav-fs;
      margin: 20px 0;
    }

    .add-zip {
      width: 86px;
      height: 48px;
      background: $white;
      border: 1px solid $input-border;
      transition: 0.5s;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid $primary-color;

        svg {
          stroke: $primary-color;
        }
      }

      @include b-radius;
      margin-top: 27px;

      &:first-child {
        margin-right: 20px;
      }

      svg {
        stroke: $black;
        transition: 0.5s;
      }

      @media (max-width: 992px) {
        margin-top: 0;
        margin-bottom: 20px;
      }

      @media (max-width: 576px) {
        margin-top: 0;
      }
    }

    .add-disable-zip {
      width: 86px;
      height: 48px;
      background: $white;
      transition: 0.5s;
      @include b-radius;
      margin-top: 27px;
      border: 1px solid $readonly;

      &:first-child {
        margin-right: 20px;
      }

      svg {
        stroke: $black;
        transition: 0.5s;
      }

      @media (max-width: 992px) {
        margin-top: 0;
        margin-bottom: 20px;
      }

      @media (max-width: 576px) {
        margin-top: 0;
      }
    }

    .nav-tabs {
      .nav-item {
        @media (max-width: 576px) {
          width: 100%;
        }

        .nav-link {
          padding: 5px 32px 25px;
          font-size: 16px;
          font-weight: $semi-bold;

          @media (width: 1280px) {
            padding: 5px 19px 25px;
          }

          @media (max-width: 1200px) {
            padding: 5px 37px 25px;
          }

          @media (max-width: 992px) {
            padding: 5px 23px 25px;
          }

          @media (max-width: 576px) {
            margin: auto;
          }

          span {
            margin-left: 5px;

            &.verify-info {
              top: 3px;

              img {
                @media (width: 1280px) {
                  width: 24px;
                }
              }
            }
          }
        }
      }
    }

    .tab-content {
      padding: 50px 50px 20px;

      @media (max-width: 992px) {
        padding: 20px;
      }

      .uploaded-files {
        img {
          max-width: 112px;
          border-radius: 12px;
          border: 1px solid $primary-color;
          box-shadow: 0px 3px 3px #00000029;
          margin: 0 15px 15px 0;
        }
      }

      p {
        &.success {
          color: $green;
        }

        &.pending {
          color: $error-red;
        }
      }
    }

    .account-type {
      margin-bottom: 20px;

      .form-check-label {
        padding: 0 20px 0 10px;
      }
    }

    .policy-type {
      margin-bottom: 20px;

      .form-check-label {
        padding: 0 20px 0 10px;
      }
    }

    .btn-wrap {
      padding-top: 30px;

      .btn {
        margin-right: 20px;

        @media (max-width: 576px) {
          margin-bottom: 20px;
          width: 100%;
          padding-top: 0px;
        }
      }
    }

    .saved-cards {
      background: $bg-color;
      padding: 30px 25px;
      @include modal-radius;
      min-height: 150px;

      p {
        &.card-number {
          font-size: $top-nav-fs;
          letter-spacing: 5px;
          font-weight: $medium;

          @media (max-width: 576px) {
            letter-spacing: 2px;
          }
        }
      }

      a {
        color: $error-red;
      }
    }
  }

  &.shipment-setup {
    padding: 30px;

    .form-label {
      color: $black;

      span {
        color: rgba($black, $alpha: 0.5);
      }
    }

    .destination {
      label {
        @include form-label;
        color: $black;
        margin-bottom: 6px;

        span {
          font-size: $link-fs;
          padding-left: 4px;
          color: rgba($black, $alpha: 0.5)
        }
      }

      .address {
        background: url(../img/ic_address.svg) 0 0 no-repeat;
        padding-left: 58px;
        line-height: 24px;
        margin-top: 6px;

        span {
          font-weight: $bold;
        }
      }
    }

    h3 {
      font-weight: $semi-bold;
      font-size: $top-nav-fs;
      margin: 20px 0;
    }

    .add-address {
      background: #F1F1F1;
      border: none;
      margin: 10px 0;

      .card-body {
        padding: 30px 30px 10px;
      }
    }

    .carried-dtls {
      .btn {
        height: 48px;
        font-size: $primary-fs;
        margin-top: 25px;
      }
    }

    .carrier-info {
      margin: 20px 0 30px 0;
      border-color: #EFEFEF;
      box-shadow: 0 3px 6px rgba($black, $alpha: 0.16);

      .card-body {
        padding: 30px;

        label {
          font-size: $link-fs;
          display: block;
          padding-bottom: 3px;
        }

        span {
          font-weight: $semi-bold;
          margin-bottom: 20px;
          display: block;
        }

        .rate {
          background: $black;
          width: fit-content;
          color: $white;
          padding: 10px 15px;
          font-weight: $semi-bold;
          margin-bottom: 20px;
          @include b-radius;
        }
      }
    }

    .generated-label {
      margin: 30px 0;
    }

    .btn-wrap {
      .btn {
        margin-right: 20px;
        height: 50px;
        font-size: $primary-fs;

        @media (max-width: 576px) {
          margin-bottom: 20px;
          width: 100%;
          padding-top: 0px;
        }
      }
    }

    .measurement {
      background: #F5F6F8;
      @include btn-radius;
      padding: 20px;
      min-height: 322px;
    }
  }

  &.refund {
    padding: 30px;

    .status {
      label {
        font-size: 16px;
        padding-bottom: 13px;
      }

      span {
        font-size: $top-nav-fs;
        font-weight: $bold;
      }

      h3 {
        font-size: 16px;
        font-weight: $bold;
        margin-top: 40px;
      }

      p {
        font-size: 16px;

        &.note {
          font-size: $primary-fs;
          font-style: italic;
          padding: 20px 0 0;
        }
      }

      .button-wrap {
        .btn-outline-primary {
          margin-right: 15px;

          @media (max-width: 576px) {
            margin-right: 10px;
          }
        }
      }
    }

    .breakdown {
      h3 {
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 20px;
      }

      .break-details {
        background: #fbfbfb;
        border: 1px solid #c7dae7;
        border-radius: 12px;
        padding: 20px;

        @media (max-width: 576px) {
          padding: 10px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            justify-content: space-between;
            border-bottom: rgba(112, 112, 112, 0.18) 1px solid;
            padding-bottom: 10px;
            margin-bottom: 10px;

            label {
              font-size: 16px;
              color: #707070;
            }

            span {
              color: $primary-color;
            }

            &:last-child {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }

            &.total {
              label {
                color: $black;
                font-weight: $medium;
              }

              span {
                font-weight: $bold;
              }
            }
          }
        }
      }
    }
  }

  &.profile-admin {
    padding: 60px;

    @media (max-width: 576px) {
      padding: 40px;
    }

    .img-wrap {
      width: 156px;
      height: 156px;
      border-radius: 78px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 78px;
        object-fit: cover;
      }
    }

    p {
      font-family: $secondary-font;
      line-height: 24px;
      margin-bottom: 12px;

      &.name {
        font-size: $top-nav-fs;
        font-weight: $semi-bold;
        padding-top: 30px;
      }

      &.address {
        font-size: $form-input-fs;
      }
    }

    .contact {
      padding: 20px 0;
      display: flex;
      justify-content: center;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      p {
        @media (max-width: 576px) {
          margin-bottom: 15px;
        }

        &.email {
          background: url(../img/mail.svg) left center no-repeat;
          padding: 0 30px 0 40px;
        }

        &.phone {
          background: url(../img/phone.svg) left center no-repeat;
          padding-left: 40px;
        }
      }
    }

    .btn-wrap {
      .btn {
        margin-right: 20px;

        @media (max-width: 576px) {
          margin-bottom: 20px;
          width: 100%;
          padding-top: 0px;
        }
      }
    }
  }

  .profile-img-action {
    .btn {
      font-size: $form-label-fs;
      min-width: 175px;
      height: 38px;
      border-radius: 22px;
      padding-left: 25px;
      padding-right: 25px;
      font-weight: $semi-bold;

      &:first-child {
        margin-bottom: 15px;
      }

      &.btn-outline-primary {
        border-color: $primary-color;
        color: $primary-color;

        &:active {
          border-color: $check-bd;
          color: $check-bd;
        }
      }

      &.btn-outline-secondary {
        border-color: $check-bd;
        color: $check-bd;

        &:hover {
          background-color: inherit;
        }

        &:active {
          background: $white;
          color: $primary-color;
          border-color: $primary-color;
          box-shadow: 0 5px 11px 0 rgba(235, 235, 235),
            0 4px 15px 0 rgba(235, 235, 235) !important;
        }

        &:focus-visible {
          box-shadow: none;
          background-color: inherit;
        }
      }
    }
  }

  &.offer-req {
    padding: 24px;

    .table {
      tbody {
        tr {
          td {
            .img-wrap {
              width: 75px;
              height: 55px;

              img {
                width: 75px;
                height: 55px;
                object-fit: cover;
                @include b-radius;
              }
            }

            white-space: inherit;

            @media(max-width:1200px) {
              white-space: nowrap;
            }
          }

          &.requested {
            td {
              background: #FFE2B233;
            }
          }
        }
      }
    }


  }
}

//Breadcrumb Styles
.breadcrumb {
  margin: 0;

  @media (max-width: 992px) {
    margin-bottom: 20px;
  }

  .breadcrumb-item {
    padding-top: 10px;

    a {
      color: $black;
    }

    &.active {
      color: #686868;
    }
  }
}

//Sub Category Style
.sub-category {
  .bd-rt {
    border-right: 1px solid #eff0f2;
  }

  .category-left {
    min-height: 460px;
    padding: 30px 20px;

    @media (max-width: 576px) {
      min-height: inherit;
    }

    h1 {
      @include head1;
      margin-bottom: 25px;
    }

    .nav-item {
      .nav-link {
        padding: 15px;
        color: $black;
        font-weight: $semi-bold;
        background: url(../img/category.svg) right center no-repeat;

        &:hover {
          color: $primary-color;
          text-decoration: none;
        }

        &.active {
          color: $white;
          background: url(../img/category-active.svg) right center no-repeat $primary-color;
        }
      }
    }
  }

  .category-right {
    padding: 30px 20px;

    h2 {
      font-size: 16px;

      @media (max-width: 576px) {
        margin-bottom: 15px;
      }
    }

    .category-header {
      border-bottom: 1px solid #c7c7c7;
      padding-bottom: 25px;

      .btn {
        height: 50px;
        font-size: $primary-fs;
        font-weight: $semi-bold;

        svg {
          stroke: $white;
          margin-right: 10px;
        }
      }
    }
  }
}

// Filter Style
.filter-wrap {
  span {
    width: 100%;
    display: block;
    font-size: 16px;
    font-weight: $semi-bold;
    margin-bottom: 20px;
  }

  .form-check {
    margin-bottom: 15px;
    line-height: 17px;

    .form-check-label {
      font-weight: $medium;
      color: $label-color;
      padding-left: 10px;
    }
  }
}

//Date picker style
.date-picker {
  label {
    @include form-label;
    margin-bottom: 6px;
    display: block;
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      @include form-input();
      padding-left: 10px;
      width: 100%;
      background: url(../img/calendar.svg) center right no-repeat $white;

      &:focus,
      &:active {
        border: 1px solid $primary-color;
      }

      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .react-datepicker__day--selected {
    background-color: $primary-color;
  }

  .react-datepicker__navigation-icon {
    margin-bottom: 0;
    font-size: 0;
    width: 14px;
    height: 14px;

    &::before {
      height: 12px !important;
      width: 12px !important;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .react-datepicker__navigation-icon--previous {
    right: 0;

    &::before {
      border: none;
      background: url(../img/calendar-previous.svg) 0 0 no-repeat;
      transform: none;
      left: 5px;
      top: 3px;
    }
  }

  .react-datepicker-popper {
    z-index: 150;
  }

  .react-datepicker__navigation-icon--next {
    left: 0;

    &::before {
      border: none;
      background: url(../img/calendar-next.svg) 0 0 no-repeat;
      transform: none;
      left: 0px;
      top: 3px;
    }
  }

  small {
    margin-top: 0.25rem;
  }
}

//About Style
.about {
  padding: 30px;

  h2 {
    font-size: $top-nav-fs;
    font-weight: $semi-bold;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 35px;
    line-height: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.feedback {
  padding: 60px;

  @media(max-width:576px) {
    padding: 30px;
  }

  .btn {
    height: 50px;
    font-size: $primary-fs;
    font-weight: $semi-bold;
  }
}

.broadcast-outer {
  padding: 30px;

  .info-wrap {
    background: $thead-color;
    padding: 15px;
    font-size: $form-input-fs;
    box-shadow: 0px 3px 6px #00000029;
    @include b-radius;
  }

  .btn-wrap {

    .btn {
      margin-right: 20px;

      @media (max-width: 576px) {
        margin-bottom: 20px;
        width: 100%;
        padding-top: 0px;
      }
    }
  }

  h3 {
    font-size: $head2-fs;
    margin-bottom: 32px;
  }

  .check-box {
    label {
      padding-left: 10px;
    }
  }
}

.react-multiple-carousel__arrow {
  background: $primary-color;
  box-shadow: 0px 3px 6px #00000029;
  min-width: 36px !important;
  min-height: 36px !important;

  &::before {
    color: $white;
  }

  &:hover,
  &:focus,
  &:active {
    background: $primary-color-hover;
  }

  &:disabled {
    background: rgba($check-bd, $alpha: 0.50);

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0px 3px 6px #00000029;
    }
  }
}

.react-multiple-carousel__arrow--left {
  left: 0;
}

.react-multiple-carousel__arrow--right {
  right: 0;
}

//New Product Style
.new-product {
  padding: 70px;

  @media (max-width: 576px) {
    padding: 30px;
  }

  h5 {
    font-size: $top-nav-fs;
    font-weight: $semi-bold;
    margin-top: 20px;
  }

  h6 {
    font-size: 16px;
    font-weight: $semi-bold;
    margin: 20px 0;
  }

  .delivery-type {
    label {
      @include form-label;
      margin-bottom: 15px;

      &.form-check-label {
        font-size: $form-input-fs;
        padding-left: 10px;
        color: $black;
      }
    }

    .availability {
      label {
        margin-bottom: 6px;
      }
    }
  }

  .description {
    .form-control {
      min-height: 144px;
    }
  }

  .multi-select-additional {
    label {
      @include form-label;
      margin-bottom: 6px;
    }

    .additional-info {
      .dropdown-container {
        @include form-input();
        min-height: 48px;
        height: fit-content;

        &:focus,
        &:active {
          border: 1px solid $primary-color !important;
        }

        &:focus-within {
          border: 1px solid $primary-color;
          box-shadow: none;
        }

        &:read-only {
          border: 1px solid $readonly;
          color: rgba($black, $alpha: 0.5);

          &:focus,
          &:active {
            border: 1px solid $primary-color !important;
          }
        }

        .dropdown-heading {
          min-height: 48px;
          height: fit-content;
        }

        .dropdown-heading-value {
          white-space: inherit;

          span {
            display: block;
            padding: 5px 0;
          }

          .selected-service {
            background: rgba(148, 218, 10, 0.6);
            color: $black;
            border-radius: 3px;
            padding: 6px;
            display: inline-block;
            margin: 3px 6px 3px 0;
          }
        }

        .dropdown-content {
          padding-top: 3px;

          .select-item {
            margin-bottom: 1px;
            padding: 15px;

            &.selected {
              background-color: #f5f4f4;
            }
          }
        }
      }
    }
  }

  .calendly-link {
    h5 {
      background: url(../img/calendly-logo.svg) 0 0 no-repeat;
      padding: 3px 0 4px 40px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 28px;
    }
  }

  .website-link {
    .web-link {
      margin-top: 39px;
    }
  }

  .price-icon {
    .input-group {
      div {
        position: relative;

        &::after {
          content: url(../img/dollar.svg);
          position: absolute;
          top: 42px;
          left: 0;
        }

        .form-control {
          padding-left: 35px;
        }
      }
    }
  }

  .location-zip-wrap {
    margin-top: 30px;
    @include b-radius;
    box-shadow: 0px 0px 6px rgba($color: $black, $alpha: 0.16);
  }
}

//Toast Style
.Toastify__toast-container {
  @media (max-width: 576px) {
    min-width: 300px !important;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.view-password {
  right: 22px;
  top: 41px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip-inner {
  background-color: $primary-color-hover;
  color: $black;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $primary-color-hover !important;
}

.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $primary-color-hover !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $primary-color-hover !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $primary-color-hover !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

.stripe-control {
  border: 1px solid #bedbef;
  border-radius: 8px;
  padding: 15px 13px;
  font-size: 15px;
}

.zip-list {
  ul {
    padding: 10px 0 0;
    list-style: none;

    li {
      background: url(../img/zipbg.svg) 0 center no-repeat;
      display: inline-block;
      padding-left: 20px;
      margin: 0 20px 10px 0;

      &:first-child {
        background-image: none;
        padding-left: 0;
      }
    }
  }
}

.reg-address {
  label {
    @include form-label;
    margin-bottom: 6px;

    span {
      &.mandatory {
        color: $error-red;
        padding-left: 2px
      }

      &.tooltip-wrap {
        margin-left: 5px;
        cursor: pointer;
      }
    }

  }

  input {
    @include form-input();
    padding: .375rem .75rem;

    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid $primary-color;
      outline: none;
    }

    &:read-only {
      border: 1px solid $readonly;
      color: rgba($black, $alpha: 0.5);
    }
  }
}

.find-any {
  justify-content: space-between;
  align-items: center;

  @media (max-width:1200px) {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    margin-bottom: 0;
    margin-top: 9px;

    a {
      font-weight: $bold;
    }
  }
}

.data-analysis-main {
  .card {
    background-color: transparent;
    border: 0;

    .card-header {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }

    h5 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 0;
    }

    .btn-outline-primary,
    .btn-primary {
      height: 50px;
      font-size: 16px;
      white-space: nowrap;
      width: fit-content;
      min-width: inherit;

      svg {
        margin-left: 10px;
      }
    }

    .btn-outline-primary {
      &.active {
        background-color: $primary-color;
        color: $white;

        svg {
          rotate: 180deg;

          path {
            fill: $white;
          }
        }
      }

      &.active-filter {
        background-color: $primary-color;
        color: $white;

        svg {

          path {
            fill: $white;
          }
        }
      }
    }

    .card-header {
      button {
        background-color: $primary-color;
        border-radius: 30px;
        border: 0;
        padding: 15px 30px;
        color: $white;
        font-weight: 600;
        font-size: 17px;

        @media (max-width:576px) {
          margin-top: 15px;
        }

        svg {
          margin-left: 10px;

          path {
            fill: $white;
          }
        }

        &.close {
          svg {
            transform: rotate(180deg);
          }
        }
      }

    }

    .analysis-data-wrap {
      align-items: center;

      .row {
        @media (max-width:992px) {
          flex-direction: column;
          display: flex;
        }
      }

      .analysis-data {
        display: flex;
        align-items: center;

        @media (max-width:992px) {
          padding-left: 15px;
          padding-top: 8px;
        }

      }
    }

    .analysis-main {
      position: relative;

      h6 {
        font-weight: bold;
        margin-bottom: 0;
      }

      .similar-code-main {
        @media (max-width:576px) {
          flex-direction: column;
          align-items: flex-start !important;
        }

        h6 {
          @media (max-width:576px) {
            margin-bottom: 15px;
          }
        }

        .similar-zip-wrap {
          overflow-y: auto;

          .similar-zipcode {
            background-color: $white;
            font-weight: $bold;
            padding: 15px;
            margin-right: 15px;
            border-radius: 10px;

            span {
              font-weight: normal;
            }
          }
        }
      }

      .left-filter-wrap {
        @media(max-width:576px) {
          flex-direction: column;
        }

        .btn {
          @media(max-width:576px) {
            margin-left: 0 !important;
            margin-bottom: 15px !important;
          }
        }
      }

      &.card-body {
        background-color: transparent;
      }

      .heatmap-filter {
        background-color: $white;
        padding: 30px 30px 15px;
        position: absolute;
        left: 0;
        top: 67px;
        box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);
        -webkit-box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);
        z-index: 10;
        @include b-radius;

        .header-filter {
          h5 {
            margin-bottom: 30px;
          }

          a {
            color: $black;
            text-decoration: underline;

            &:hover,
            &:focus,
            &:active {
              text-decoration: none;
            }
          }
        }

        .form-check {
          margin-right: 40px;
          margin-bottom: 40px;

          .form-check-input {
            background-color: $input-border;
            border-color: $input-border;

            &:checked {
              background-color: $primary-color;
              border-color: $primary-color;
            }
          }

          label {
            margin-left: 10px;
          }
        }

        &.race-ethnicity {
          @media (max-width:576px) {
            top: 130px;
          }
        }

        &.age-sex {
          @media (max-width:576px) {
            top: 195px;
          }
        }

        &.filter-ic {
          @media (max-width:992px) {
            top: 132px;
          }

          @media (max-width:576px) {
            top: 260px;
          }
        }
      }
    }

    .color-box {
      margin: 15px -7px;

      .col {
        background-color: $white;
        display: flex;
        align-items: center;
        margin: 15px 7px;
        padding: 15px;
        border-radius: 10px;

        &.orange {
          background-color: #FFF0B0;
        }

        &.blue {
          background-color: #C5E3FF;
        }

        &.green {
          background-color: #AFFFD2;
        }

        &.redbg {
          background-color: #FFD3CB;
        }

        &.yellow {
          background-color: #E1FFA5;
        }

        &.pink {
          background-color: #FFD5F4;
        }

        h6 {
          display: block;
        }
      }
    }

    .analysis-map {
      background-color: $white;
      padding: 20px 10px;
      margin-bottom: 30px;
      min-height: 305px;

      h3 {
        font-size: $top-nav-fs;
        font-weight: $regular;
      }

      .legends {
        .legend-item {
          margin-top: 10px;

          span {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin-right: 8px;
            display: block;

            &.male {
              background: $primary-color;
            }

            &.female {
              background: $secondary-color;
            }
          }

          label {
            font-size: $link-fs;
            font-weight: $bold;
          }
        }
      }

      &.point-interests {
        padding-left: 25px;
        padding-right: 25px;

        p {
          font-size: $link-fs;
          min-height: 46px;
          padding-left: 62px;
          line-height: 21px;

          &.college {
            background: url(../img/iccollege.svg) 0 0 no-repeat;
          }

          &.train {
            background: url(../img/ictrain.svg) 0 0 no-repeat;
          }

          &.airport {
            background: url(../img/icairport.svg) 0 0 no-repeat;
          }
        }
      }
    }

    .accordion-wrap {
      .accordion {
        .accordion-item {
          border: none;
          background: $white;
          @include b-radius;

          &:first-child {
            margin-bottom: 20px;
          }

          .accordion-header {
            .accordion-button {
              position: relative;
              background: transparent;
              font-size: $top-nav-fs;
              font-weight: $medium;
              padding: 20px;

              &::after {
                display: none;
              }

              .btn {
                position: absolute;
                right: 20px;
                font-size: $primary-fs;
                height: 38px;
                font-weight: $regular;
                padding: 0 15px;

                @media(max-width:576px) {
                  position: inherit;
                  margin: 10px 0 0 20px;
                }

                svg {
                  fill: $white;
                  transform: rotate(180deg);
                }
              }

              &.collapsed {
                .btn {
                  svg {
                    transform: rotate(0deg);
                  }
                }
              }

              &:focus {
                box-shadow: none;
              }

              &:not(.collapsed) {
                color: $black;
                box-shadow: none !important;
                border-bottom: 1px solid #dee2e6;
              }

              @media(max-width:576px) {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
              }
            }
          }

          .accordion-body {
            .accordion-action {
              justify-content: end;

              .cut-select {
                min-width: inherit;

                &:nth-child(2) {
                  margin-left: 20px;

                  @media(max-width:576px) {
                    margin-left: 0;
                    margin-top: 20px;
                  }

                }

                .filter__control {
                  height: 40px;
                  width: 200px !important;

                  @media(max-width:576px) {
                    width: 100% !important;
                  }
                }

              }

              .react-datepicker-wrapper {
                input {
                  height: 40px;
                  border-radius: 20px;
                }
              }

              .react-datepicker__input-container {
                width: 280px !important;

                @media(max-width:576px) {
                  width: 100% !important;
                }
              }

              @media(max-width:576px) {
                flex-direction: column;
              }
            }

            .graph-wrap {

              .legends {
                .legend-item {
                  margin-top: 10px;

                  span {
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    margin-right: 8px;
                    display: block;

                    &.male {
                      background: $primary-color;
                    }

                    &.female {
                      background: $secondary-color;
                    }
                  }

                  label {
                    font-size: $link-fs;
                    font-weight: $bold;
                  }
                }
              }
            }

            .accordion-bottom {
              justify-content: space-between;
              padding: 0 20px 0 61px;

              @media(max-width:576px) {
                flex-direction: column;
              }


              .weeklinks {
                a {
                  font-size: $form-label-fs;
                  color: #333333;
                  font-weight: $medium;

                  &.previous {
                    background: url(../img/icprevious.svg) 0 center no-repeat;
                    margin-right: 20px;
                    padding-left: 15px;
                  }

                  &.next {
                    background: url(../img//icnext.svg) right center no-repeat;
                    padding-right: 15px;
                  }
                }
              }

              .legend-wrap {
                width: 270px;

                @media(max-width:576px) {
                  width: 100%;
                  margin-top: 20px;
                }

                .legend {
                  background: rgb(252, 209, 72);
                  background: linear-gradient(90deg, rgba(252, 209, 72, 1) 0%, rgba(225, 107, 77, 1) 100%);
                  height: 6px;
                  border-radius: 6px;
                }

                .legend-dtls {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.analysis-data {
  font-weight: $bold;

  span {
    font-weight: normal;
  }
}

.analysis-popover {
  border: 0;
  box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.35);

  .popover-body {
    padding: 0;

    ul {
      padding: 0;

      li {
        list-style: none;
        border-bottom: $thead-color solid 1px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;

        span {
          font-weight: bold;
          padding-left: 10px;
        }
      }
    }
  }
}

.zcta-data-analysis {
  .border {
    border-color: $input-border !important;
  }

  .table-bordered {
    margin-top: 15px;
    border-color: $input-border;

    thead {
      tr {
        th {
          color: $black;

          &:first-child {
            border-radius: 0;
          }

          &:last-child {
            border-radius: 0;
          }
        }
      }
    }

    tbody {
      td {
        border-color: $input-border;

        a {
          color: $primary-color;
        }
      }
    }
  }
}

.check-secondary {
  .form-check {
    margin-right: 40px;
    margin-bottom: 40px;

    .form-check-input {
      background-color: $input-border;
      border-color: $input-border;

      &:checked {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }

    label {
      margin-left: 10px;
    }
  }
}

.character-count {
  position: absolute;
  right: 12px;
  font-size: 12px;
  top: 3px;
  color: rgba(0, 0, 0, 0.50);
}

.offer-req-head {
  padding-top: 24px;

  .input-group {
    .form-control {
      border-radius: 25px;
    }
  }

  .cut-select {
    min-width: inherit;

    @media(max-width:992px) {
      margin-bottom: 20px;
    }
  }

  .btn {
    height: 48px;
  }

  .search-input {
    .form-control {
      padding-right: 48px;
    }

    .clear-search {
      right: 16px;
    }
  }
}

.selected-product {
  width: fit-content;
  padding: 8px;
  @include b-radius;
  background: rgba($primary-color, $alpha: 0.20);
}

.offer-audience-dtls {
  border: 1px solid $input-border;
  @include b-radius;
  padding: 18px;

  span {
    label {
      font-weight: $bold;
    }

    @media(max-width:992px) {
      margin-bottom: 20px;
    }

    &:last-child {
      @media(max-width:992px) {
        margin-bottom: 0;
      }
    }
  }

}

.react-multi-carousel-list {
  padding: 0;
}

.react-multi-carousel-item {
  padding: 10px;
}

.react-multi-carousel-dot-list {
  bottom: -15px;
}

.selected-offers {
  h3 {
    font-size: $head2-fs;
    margin-bottom: 32px;
  }
}

.react-multi-carousel-dot--active button {
  background-color: $primary-color;
  border-color: $primary-color;
}

.broadcast-offer-list {
  .table {
    tbody {
      tr {
        td {
          .img-wrap {
            width: 75px;
            height: 55px;

            img {
              width: 75px;
              height: 55px;
              object-fit: cover;
              @include b-radius;
            }
          }

          white-space: inherit;

          @media(max-width:1200px) {
            white-space: nowrap;
          }
        }

        &.requested {
          td {
            background: #FFE2B233;
          }
        }
      }
    }
  }


}